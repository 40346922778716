@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');

body {
	font-family: 'Rubik', sans-serif;
	@apply bg-slate-900 text-slate-200;
}

/* GENERAL */
.card {
	@apply border-2
	bg-slate-900 border-slate-800 rounded-md;
}
.section-title {
	@apply text-2xl text-center uppercase;
}
.input {
	@apply card w-full p-2 text-lg outline-none focus:border-slate-700 duration-300 placeholder:text-slate-500
	autofill:text-red-500
	read-only:bg-blue-500;
}
.button-hover {
	@apply hover:bg-slate-800 hover:text-slate-500 duration-300 cursor-pointer;
}
.button {
	@apply w-fit text-slate-400 button-hover;
}
.socials-icon {
	@apply w-[40px] h-[40px] rounded-md p-[6px] button-hover;
}
.animate-slide-down {
	animation: slide-down 500ms ease-in-out;
}
@keyframes slide-down {
	from {
		opacity: 0;
		transform: translateY(-10%);
	}
	to {
		opacity: 1;
		transform: translateY(0%);
	}
}

/* NAVIGATION */
.nav-link {
	@apply py-3 px-5 mx-5 rounded-md hover:bg-slate-800 duration-300 cursor-pointer hover:text-slate-400;
}

/* ABOUT */
.box-inset-shadow {
	box-shadow: 0 0 0 1em rgb(15, 23, 42), inset 0 0 3em 4em rgb(15, 23, 42);
}
.emphasis {
	text-shadow: 0 0 5px #FBBF24;
	color: #FBBF24;
}

/* EDUCATION */
.edu-time-container {
	@apply relative z-10 h-[200px] flex before:w-[19px] before:h-[19px] before:bg-amber-500 before:absolute before:rounded-full before:left-[15px] before:z-30 before:shadow-lg before:shadow-orange-400 after:absolute after:h-full after:bg-slate-800 after:w-[5px] after:rounded-full after:top-0 after:left-[22px] after:z-20;
}
.edu-card {
	@apply card mr-5 ml-10 p-4 flex flex-col gap-3 h-fit my-auto button-hover w-full;
}

/* PROJECTS */
.tech {
	@apply border py-1 px-3 rounded border-slate-700 text-slate-400;
}

/* GAME */
.game-cell {
	@apply rounded-md w-[60px] h-[60px] bg-slate-800;
}